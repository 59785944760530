import React from 'react'
import '../styles/not-found.css'

const NotFound = () => {
    return (
        <>
            <h1>NotFound</h1>
        </>
    )
}

export default NotFound